import React, { useEffect, useState } from 'react';
import { CircularProgress, Grid } from "@mui/material";
import MarketingCampaign from 'app/shared/widgets/MarketingCampaign/MarketingCampaign';
import ObjectCountRevenue from "../../../shared/metrics/ObjectCountCards/ObjectCountRevenue";
import AccountBalanceWalletRoundedIcon from '@mui/icons-material/AccountBalanceWalletRounded';
import { dashBoardApi, lasttransactions } from 'backendServices/ApiCalls';
import { Group } from '@mui/icons-material';
import PaidIcon from '@mui/icons-material/Paid';
import Div from '@jumbo/shared/Div';


const Crypto = () => {
    const [lasttransactionsdata, setLastTransactionsData] = useState([])
    const [dashboardData, setDashboardData] = useState({})
    const [loading, setLoading] = useState(false)

    const LastWeekTransactions = () => {
        lasttransactions((response) => {
            setLastTransactionsData(response?.data?.data?.entries)
        }, (error) => {
            console.log(error?.response?.data);
        })
    }

    const DashBoardFun = () => {
        setLoading(true)
        dashBoardApi((response) => {
            setDashboardData(response?.data?.data)
            setLoading(false)
        }, (error) => {
            setLoading(false)
            console.log(error?.response?.data);
        })
    }


    useEffect(() => {
        LastWeekTransactions();
        DashBoardFun();
    }, [])


    if (loading) {
        return <Div
            sx={{
                display: 'flex',
                minWidth: 0,
                alignItems: 'center',
                alignContent: 'center',
                height: '100%',
            }}
        >
            <CircularProgress sx={{ m: '-40px auto 0' }} />
        </Div>
    }

    return (
        <Grid container spacing={3.75}>
            <Grid item xs={12} sm={6} lg={4}>
                <ObjectCountRevenue value={dashboardData?.usdFee} title='USD Membership Fee' color="success.main" icon={<AccountBalanceWalletRoundedIcon fontSize='large' />} vertical={true} />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <ObjectCountRevenue value={dashboardData?.cadFee} title='CAD Membership Fee' color="warning.main" icon={<AccountBalanceWalletRoundedIcon fontSize='large' />} vertical={true} />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <ObjectCountRevenue value={dashboardData?.totalLevelBonus?.toFixed(2)} title='Total Level Bonus' color="primary.main" icon={<AccountBalanceWalletRoundedIcon fontSize='large' />} vertical={true} />
            </Grid>

            <Grid item xs={12} sm={6} lg={4}>
                <ObjectCountRevenue value={dashboardData?.companyCommission?.toFixed(2)} title='Company Commission' color="success.main" icon={<AccountBalanceWalletRoundedIcon fontSize='large' />} vertical={true} />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <ObjectCountRevenue value={dashboardData?.payout?.toFixed(2)} title='Total Payout' color="warning.main" icon={<PaidIcon fontSize='large' />} vertical={true} />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <ObjectCountRevenue value={dashboardData?.totalUsers} title='App Users' color="info.main" icon={<Group fontSize='large' />} vertical={true} />
            </Grid>
            <Grid item xs={12} lg={12}>
                <MarketingCampaign lasttransactionsdata={lasttransactionsdata} />
            </Grid>
        </Grid>
    );
};

export default Crypto;
