import { Box, Button, Paper,CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Tooltip } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { getproduct, postRequest } from 'backendServices/ApiCalls';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Div from "@jumbo/shared/Div";
import React, { useEffect, useState } from 'react'
import { Close } from '@mui/icons-material';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { FileCopy as FileCopyIcon, CheckCircle as CheckCircleIcon } from '@mui/icons-material';
import JumboDemoCard from '@jumbo/components/JumboDemoCard';

const gridDesign = {
    '& .MuiDataGrid-toolbarContainer': {
        '& .MuiButton-text': {
            fontSize: '13px !important',
            color: '#fff',
        },
        '& .MuiBadge-badge': {
            backgroundColor: '#074682',
        },
        '& .MuiInput-root': {
            borderRadius: 2,
            paddingLeft: 2,
            overflow: 'hidden',
        },
    },
};
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 650,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};




const PendingOrder = () => {
    const [orderData, setOrderData] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [alertopen, setAlertOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [selectedorderdata, setSelectedOrderData] = useState([]);
    const [updatedorderstatus, setUpdateOrderStatus] = useState([]);
    const [rejectOrder, setRejectOrder] = useState([]);
    const [imageurl, setImageUrl] = useState(null);
    const [loadingStates, setLoadingStates] = useState({});
    const [copiedRows, setCopiedRows] = useState([]);

 const Swal = useSwalWrapper();
 
const sweetAlerts = (orderId) => {
    Swal.fire({
        title: 'Are you sure to update Status?',
        text: "You are going apporve status ",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Approve!',
        cancelButtonText: 'No, cancel!',
        reverseButtons: true,
    }).then(result => {
        if (result.value) { 
            handleOrderStatus(orderId)
            Swal.fire('Approved!', 'Status has been approved.', 'success');
        } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
        ) {
            Swal.fire('Cancelled', 'Ok with Pending :)', 'error');
        }
    });
};


const RejectAlert = (orderId) => {
    Swal.fire({
      title: 'Enter your rejection reason',
      html: `
        <input type="text" id="rejectionReason" class="swal2-input" placeholder="Enter rejection reason">
      `,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Reject Order',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
      preConfirm: () => {
        const rejectionReason = document.getElementById('rejectionReason').value;

        if (!rejectionReason) {
          Swal.showValidationMessage('Please enter  a rejection reason');
        }
        return { rejectionReason };
      },
    }).then((result) => {
      if (result.value) {
        const { rejectionReason } = result.value;
        RejectOrder(orderId, rejectionReason);
        Swal.fire('Order Rejected!', 'Your rejection sent.', 'success');
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Order not rejected.', 'error');
      }
    });
  };
  
    const OrderData = () => {
        let params = {
            status: 'pending',
        };
        postRequest(
            '/getorderlist',
            params,
            (response) => {
                if (response?.data?.status === 'success') {
                    setOrderData(response?.data?.data);
                }
                
            },
            (error) => {
                console.log(error?.response?.data);
            }
        );
    };


    const handleOrderDetails = (id) => {
        setLoadingStates((prevState) => ({ ...prevState, [id]: true }));
        setTimeout(() => {
        let params = {
            orderid: id
        }
        postRequest(
            '/getorderdetails',
            params,
            (response) => {
                if (response?.data?.status === 'success') {
                    setSelectedOrderData(response?.data?.data);
                    setOpen(true)
                }
                setLoadingStates((prevState) => ({ ...prevState, [id]: false }));
            },
            (error) => {
                console.log(error?.response?.data);
            }
            
        );
    }, 2000); // Simulate a 2-second delay, replace with your actual API call
};
   

    const handleOrderStatus = (id ) => {
        let params = {
            orderstatus: 'approved',
            orderId : id,
        }
        console.log("params",params)
        postRequest(
            '/updateorderstatus',
            params,
            (response) => {
                if (response?.data?.status === 'success') {
                    setUpdateOrderStatus(response?.data?.data);
                    setAlertOpen(true)
                    OrderData()
                }
            },
            (error) => {
                console.log(error?.response?.data);
            }
        );
    }

    const RejectOrder = (id , rejectionreason ) => {
        let params = {
            rejectorder: 'rejected',
            orderId : id,
            rejectionreason: rejectionreason ,
        }
        postRequest(
            '/rejectorder',
            params,
            (response) => {
                if (response?.data?.status === 'success') {
                    setRejectOrder(response?.data?.data);
                    setAlertOpen(true)
                    OrderData()
                }
            },
            (error) => {
                console.log(error?.response?.data);
            }
        );
    }


        getproduct((response) => {
          setImageUrl(response?.data?.imageURL)
          if (response?.data === "success") { // change in everyone
            console.log("response get Successfully");
          }
        }, (error) => {
          console.log(error?.response?.data);
        })
  const subtotal = selectedorderdata.reduce((total, item) => total + item.price * item.quantity, 0);
        

    const columns = [

        {
            field: 'id',
            headerName: 'Sr #',
            width: 50,
        },
        {
            field: 'fullname',
            headerName: 'User',
            width: 160,
            valueGetter: (params) =>
                `${params.row.firstname || ''} ${params.row.lastname || ''}`,
        },
        {
            field: 'orderid',
            headerName: 'Order ID',
            width: 150,
        },
        {
            field: 'totalamount',
            headerName: 'Order Amount',
            width: 150,
            renderCell: (params) => `$${params.value}`
        },
        {
            field: 'shipping_charges',
            headerName: 'Shipping Charges',
            width: 150,
            renderCell: (params) => `$${params.value}`

        },
        {
            field: 'aa',
            headerName: 'Order Details',
            renderCell: (params) => (
                <div>
                    {/* Button to open the modal */}
                    <Button variant="outlined" disabled={loadingStates[params.row.orderid]} size='small' color="primary" onClick={() => handleOrderDetails(params.row.orderid)}>
                    {loadingStates[params.row.orderid] ? (
              <CircularProgress size={24} />
            ) : (
              'Details'
            )}
                    </Button>
                    
                </div>
            ),
            width: 150,
        },
        {
            field: 'address',
            headerName: 'Address',
            width: 250,
            renderCell: (params) => {
                const isCopied = copiedRows.includes(params.row.id);
                
                const handleCopyClick = () => {
                  navigator.clipboard.writeText(`${params.value}, ${params.row.cityname}, ${params.row.statename}, ${params.row.countryname}`)
                    .then(() => {
                      setCopiedRows((prevCopiedRows) => [...prevCopiedRows, params.row.id]);
                    })
                    .catch((error) => {
                      console.error('Copy failed:', error);
                    });
                };
      
                return (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {isCopied ? (
                      <CheckCircleIcon style={{ marginRight: '4px', color: 'green' }} />
                    ) : (
                      <Tooltip title="Copy Details" placement="top">
                        <IconButton
                          onClick={handleCopyClick}
                          size="small"
                          style={{ marginRight: '4px' }}
                        >
                          <FileCopyIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    <span>{`${params.value}, ${params.row.cityname}, ${params.row.statename}, ${params.row.countryname}`}</span>
                  </div>
                );
              },
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 150,
        },
        {
            field: 'paymentstatus',
            headerName: 'Payment Status',
            width: 150,
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 150,
            renderCell: (params) => (
                <div>
                    {/* Button to open the modal */}
                    
                    <CheckCircleIcon sx={{cursor: 'pointer'}} open={alertopen} color='success'  onClick={() => sweetAlerts(params.row.orderid)}/>
                    <HighlightOffRoundedIcon sx={{cursor: 'pointer'}} color='error' onClick={() => RejectAlert(params.row.orderid , params.row.rejectionreason)}  />
                </div>
            ),
        },


    ];
    
    useEffect(() => {
        OrderData();
    }, []);

    return (
        <JumboDemoCard
        title={"Pending Orders"}
        wrapperSx={{backgroundColor: 'background.paper', pt: 0}}>
   
    <Box sx={{ height: 400, width: 1 }}>
                <DataGrid
                    initialState={{
                        initialState: {
                            columns: {
                                columnVisibilityModel: {
                                    id: false,
                                    avatar: false,
                                    website: false,
                                    email: false,
                                    phone: false,
                                    username: false,
                                    city: false,
                                    company: false,
                                    position: false,
                                    lastUpdated: false,
                                    salary: false,
                                },
                            },
                        },
                        pagination: { paginationModel: { pageSize: 6 } },
                    }}
                    rows={orderData}
                    columns={columns}
                    slots={{ toolbar: GridToolbar }}
                    sx={gridDesign}
                    pageSizeOptions={[6, 12, 18, 24, 30]}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                        },
                    }}
                />
            </Box>



            {/* Modal */}
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ minWidth: 350 }}
            >
                <Div sx={style}>
                    <Typography id="modal-modal-title" variant="h4" 
                        component="h2"
                        display='flex' 
                        justifyContent='space-between'>
                        Order Details
                        <Close style={{cursor: 'pointer'}}  onClick={handleClose}/>
                    </Typography>
                    
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <TableContainer component={Paper} sx={{ overflowY : 'scroll', height: "auto"}}>
                    <Table sx={{ minWidth: 350  }}>
                      <TableHead style={{ fontWeight: 'bolder' }}>
                        <TableRow >
                          <TableCell>Product Image</TableCell>
                          <TableCell>Product</TableCell>
                          <TableCell>Price</TableCell>
                          <TableCell>Quantity</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {selectedorderdata.map((item) => (
                          <>
                            <TableRow key={item.id}>
                              <TableCell>
                                <img src={`${imageurl}${item.picture}`} alt={item.title} height="40"
                                  style={{ borderRadius: "50%", width: '40px' }} />

                              </TableCell>
                              <TableCell > {item.title}</TableCell>
                              <TableCell>${item.price}</TableCell>
                              <TableCell>{item.quantity}</TableCell>
                            </TableRow>

                          
                       

                        
                        </>
                        
                        ))}
                        <TableCell
                        align="right" colSpan={4}>
                        
                             Subtotal: ${subtotal}
                        </TableCell>
                      </TableBody>
                      
                    </Table>
                  </TableContainer>
                    </Typography>
                </Div>
            </Modal>
            

            
            
        
            
     
            </JumboDemoCard>
    )
}

export default PendingOrder
