import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import JumboDemoCard from '@jumbo/components/JumboDemoCard/JumboDemoCard';
import { postRequest } from 'backendServices/ApiCalls';

const VISIBLE_FIELDS = ['sr', 'sender', 'amount', 'date'];
const InvestmentCommission = () => {

  const data = [
    {
      'id': 1,
      'username': 'user1',
      'receiver': 'Company',
      'amount': '$10',
      'type': 'Investment',
      'details': 'Company has received $10 from user1',
      'createdat': '10-04-2023',
    },
    {
      'id': 2,
      'username': 'user2',
      'receiver': 'Company',
      'amount': '$20',
      'type': 'Mortgage',
      'details': 'Company has received $20 from user2',
      'createdat': '10-04-2023',
    },
  ]


  const [unilevelbonus, setUnilevelBonusData] = useState([])
  const UnilevelData = () => {
    let params = {
      type: "investment_commission"
    }
    postRequest('/report', params, (response) => {
      setUnilevelBonusData(response?.data?.data)
    }, (error) => {
      console.log(error?.response?.data);
    })
  }

  useEffect(() => {
    UnilevelData();
  }, [])


  const initialState = {
    initialState: {
      columns: {
        columnVisibilityModel: {
          id: false,
          avatar: false,
          website: false,
          email: false,
          phone: false,
          username: false,
          city: false,
          company: false,
          position: false,
          lastUpdated: false,
          salary: false,
        }
      }
    }
  }




  const gridDesign = {
    '& .MuiDataGrid-toolbarContainer': {
      '& .MuiButton-text': {
        fontSize: '13px !important',
        color: '#fff',
      },
      '& .MuiBadge-badge': {
        backgroundColor: '#074682',
      },
      '& .MuiInput-root': {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: 'hidden',
      },

    }
  }
  // Otherwise filter will be applied on fields such as the hidden column id
  const columns = [

    {
      field: "senderusername",
      headerName: "Sender",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    {
      field: "receiverusername",
      headerName: "Receiver",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    {
      field: "amount",
      headerName: "Amount",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      editable: true,
      groupable: false,
      aggregable: false,
      renderCell: (params) => `$${params.value}`
    },
    {
      field: "type",
      headerName: "Type",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    {
      field: "details",
      headerName: "Details",
      dataGeneratorUniquenessEnabled: true,
      width: 250,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    {
      field: "createdat",
      headerName: "Date",
      dataGeneratorUniquenessEnabled: true,
      width: 200,
      editable: true,
      groupable: false,
      aggregable: false,
    }

  ]

  // const rows = data
  const rows = unilevelbonus
  


  return (
    <JumboDemoCard
      title={"Investment Commission"}
      wrapperSx={{ backgroundColor: 'background.paper', pt: 0 }}
    >
      <Box sx={{ height: 460, width: 1 }}>
        <DataGrid
          initialState={{
            initialState,
            pagination: { paginationModel: { pageSize: 6 } },
          }}
          rows={rows}
          columns={columns}
          slots={{ toolbar: GridToolbar }}
          sx={gridDesign}
          pageSizeOptions={[6, 12, 18, 24, 30]}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </Box>
    </JumboDemoCard>
  )
}

export default InvestmentCommission