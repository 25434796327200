import React, {  useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import JumboDemoCard from '@jumbo/components/JumboDemoCard/JumboDemoCard';
import { IconButton, Tooltip } from '@mui/material';
import { FileCopy as FileCopyIcon, CheckCircle as CheckCircleIcon } from '@mui/icons-material';

import CancelIcon from '@mui/icons-material/Cancel';

import { CircularProgress } from '@mui/material';
import Div from '@jumbo/shared/Div/Div';
import { useEffect } from 'react';
import { postRequest } from 'backendServices/ApiCalls';

const VISIBLE_FIELDS = ['sr','country', 'state', 'city', 'amount'];


const ManageShipping = ({isLoading,setShippingList,shippingList,setalertData}) => {
    const [loadingRowId, setLoadingRowId] = useState(null);

    const generateRowId = () => {
        return `row-${Math.random().toString(36).substr(2, 9)}`;
      };

    
        const gridDesign = {
          '& .MuiDataGrid-toolbarContainer': {
            '& .MuiButton-text': {
              fontSize: '13px !important',
              color: '#fff',
            },
            '& .MuiBadge-badge': {
              backgroundColor: '#074682',
            },
            '& .MuiInput-root': {
              borderRadius: 2,
              paddingLeft: 2,
              overflow: 'hidden',
            },
          },
        };
      
            


          const handleActionClick = (id) => {
              setLoadingRowId(id); 
              postRequest('deleteshippinglist',{id}, (response) => {
                if(response?.data?.status === "success")
                {
                  const filteredShippingList = shippingList.filter((shippingItem) => {
                    return shippingItem.tid !== id;
                  });
                  // Set the filtered list using setShippingList
                  setShippingList(filteredShippingList);
                  setLoadingRowId(null);
                  setalertData({
                    show: true,
                    message: response?.data?.message,
                    variant: "success"
                  })
                }

              });

          };
    if (isLoading) {
        return  <Div
        sx={{
            display: 'flex',
            minWidth: 0,
            alignItems: 'center',
            alignContent: 'center',
            height: '100%',
        }}
      >
        <CircularProgress sx={{m: '-40px auto 0'}}/>
      </Div>
    }
    const rows= shippingList || []
    let idCounter = 1;
    const rowsWithId = rows.map((row) => ({
      ...row,
      id: idCounter++,
    }));
  return (
    <JumboDemoCard title={'Manage Shipping Charges'} wrapperSx={{ backgroundColor: 'background.paper', pt: 0 }}>

      <Box sx={{ height: 400, width: 1 }}>
        <DataGrid
          initialState={{
            initialState: {
              columns: {
                columnVisibilityModel: {
                  id: false,
                  coinname: false,
                  walletmessage: false,
                  walletaddress: false,
                  walletqrcode: false
                },
              },
            },
            pagination: { paginationModel: { pageSize: 24 } },
          }}
          rows={rowsWithId}
          getRowId={(row) => generateRowId()}
          columns={[
            ...VISIBLE_FIELDS.map((field) => {
              if (field === 'sr') {
                return {
                  field,
                  headerName: 'Sr',
                  width: 150,
                  renderCell: (params) => <strong>{params.row.id}</strong>,
                };
              } else if (field === 'country') {
                return {
                  field,
                  headerName: 'Country',
                  width: 150,
                };
              } else if (field === 'state') {
                return {
                  field,
                  headerName: 'State',
                  width: 150,
                };
              } 
              else if (field === 'city') {
                return {
                  field,
                  headerName: 'City',
                  width: 150,
                };
              } 
              else if (field === 'amount') {
                return {
                  field,
                  headerName: 'Amount',
                  width: 200,
                  renderCell: (params) => `$${params.value}`

                };
              }
              else{
              return {
                field,
                headerName: field,
                width: 150,
              };
            }
            }),
            {
              field: 'actions',
              headerName: 'Actions',
              width: 120,
              renderCell: (params) => (
                <>
                  <Tooltip title="Delete" placement="top">
                    <IconButton
                      onClick={() => handleActionClick(params.row.tid)}
                      sx={{ color: 'error.main' }}
                      disabled={loadingRowId === params.row.tid} // Disable the button while processing
                    >
                  {
                  loadingRowId === params.row.tid ? (
                    <CircularProgress size={24} sx={{ ml: 2 }} />
                  ):(<CancelIcon />)
                    }
                      
                    </IconButton>
                  </Tooltip>

                </>
              ),
            },
            
          ]}
          slots={{ toolbar: GridToolbar }}
          sx={gridDesign}
          pageSizeOptions={[6, 12, 18, 24, 30]}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </Box>
    </JumboDemoCard>
  )
}

export default ManageShipping