import React, { useEffect } from "react";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import {
  Autocomplete,
  Box,
  CircularProgress,
  Grid,
  List,
  MenuItem,
  Typography,
} from "@mui/material";
import { useState } from "react";
import {
  commisionDistributionApi,
  getuserslist,
  checkUserLicenseStatus,
} from "backendServices/ApiCalls";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import * as yup from "yup";
import { Form, Formik } from "formik";
import Div from "@jumbo/shared/Div/Div";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { LoadingButton } from "@mui/lab";
import { CheckCircleOutline } from "@mui/icons-material";

const validationSchema = yup.object({
  userid: yup.mixed().required("User Name is required"),
  type: yup.string().required("Balance Type is required"),
  amount: yup.number().required("Amount is required"),
});

const CommissionDistribution = () => {
  const [userlist, setUserList] = useState();
  const [userId, setUserId] = useState(null);
  const [buttonStatus, setButtonStatus] = useState(true);
  const [msg, setMsg] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [alertData, setalertData] = React.useState({
    show: false,
    message: "",
    variant: "",
  });

  const [selectedType, setSelectedType] = useState(null);

  const userslist = () => {
    getuserslist((response) => {
      setUserList(response?.data?.userdata);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    userslist();
  }, []);

  const handleSubmit = (data, setSubmitting, resetForm) => {
    let params = {
      userId: data?.userid?.userid,
      commissionType: data?.type,
      amount: data?.amount,
    };

    commisionDistributionApi(
      params,
      (response) => {
        if (response?.data?.status === "error") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "error",
          });
          setSubmitting(false);
        } else if (response?.data?.status === "succes") {
          resetForm();
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "success",
          });
          setSubmitting(false);
        } else {
          setalertData({
            show: true,
            message: "Something went wrong, please try again later",
            variant: "error",
          });
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  function handleTypeChange(event) {
    setButtonStatus(true);
    const selectedValue = event.target.value;
    if (selectedValue === "mortgage") {
      setMsg("Verifying license...");
      let params = {
        userid: userId,
      };
      checkUserLicenseStatus(
        params,
        (response) => {
          if (response?.data?.status === "succes") {
            if (response?.data?.licenseStatus === "pending") {
              setButtonStatus(true);
              setMsg(response?.data?.message);
            } else {
              setButtonStatus(false);
              setMsg("verified");
            }
          } else {
            setalertData({
              show: true,
              message: "Something went wrong, please try again later",
              variant: "error",
            });
          }
        },
        (error) => {
          console.log(error?.response?.data);
        }
      );
    } else {
      setMsg("");
      setButtonStatus(false);
    }
  }

  function handleUserChange(value) {
    setUserId(value?.userid);
    setSelectedType(null);
    setMsg("");
  }

  return (
    <Grid
      container
      fullWidth
      sm={12}
      xs={12}
      p={2}
      alignItems="center"
      justifyContent="center"
    >
      <Grid item sm={6} xs={12}>
        <JumboCardQuick title={"Commission Distribution"} noWrapper>
          {alertData.show && (
            <SweetAlert alertData={alertData} setalertData={setalertData} />
          )}
          {isLoading ? (
            <Div
              sx={{
                mt: "20%",
                ml: "45%",
                mb: "20%",
              }}
            >
              <CircularProgress />
            </Div>
          ) : (
            <List disablePadding sx={{ mb: 2 }}>
              <Formik
                validateOnChange={true}
                initialValues={{
                  userid: null,
                  type: "",
                  amount: "",
                }}
                validationSchema={validationSchema}
                onSubmit={(data, { setSubmitting, resetForm }) => {
                  setSubmitting(true);
                  handleSubmit(data, setSubmitting, resetForm);
                }}
              >
                {({ isSubmitting, setFieldValue, values }) => (
                  <Form
                    style={{ textAlign: "left" }}
                    noValidate
                    autoComplete="off"
                  >
                    <Div sx={{ mt: 1, pl: 2, pr: 2 }}>
                      <Autocomplete
                        sx={{ width: "100%" }}
                        value={values.userid}
                        onChange={(event, newValue) => {
                          setFieldValue("userid", newValue);
                          handleUserChange(newValue);
                        }}
                        id="vehmake-select"
                        options={userlist}
                        autoHighlight
                        getOptionLabel={(option) => option.username} // Display the title in the dropdown
                        getOptionSelected={(option, value) =>
                          option.userid === value.userid
                        } // Match by code
                        renderInput={(params) => (
                          <JumboTextField
                            {...params}
                            name="userid"
                            label="Select User"
                          />
                        )}
                      />
                    </Div>
                    {/* <Div sx={{  mb: 3, pl: 2, pr: 2 }}>
                      {userlist.map(value =>(
                        <>
                        {console.log("value", value)}
                          <p>Current Balance of Ahsan is 100 </p>
                          </>
                        ))}
                    </Div> */}

                    {userId === null ? null : (
                      <Box sx={{ mt: 1, mb: 1, pl: 2, pr: 2 }}>
                        <JumboTextField
                          fullWidth
                          name="type"
                          label="Commission Type"
                          select
                          value={selectedType} // Add a value prop to control the selected value
                          inputProps={{
                            onChange: (event) => handleTypeChange(event),
                          }}
                        >
                          <MenuItem value="investment">Investment</MenuItem>
                          <MenuItem value="mortgage">Mortgage</MenuItem>
                        </JumboTextField>
                      </Box>
                    )}

                    <Box sx={{ mb: 1, pl: 2, pr: 2 }}>
                      {msg === "verified" ? (
                        <div>
                          <Typography variant="p" style={{ color: "green" }}>
                            {msg}
                            <CheckCircleOutline style={{ color: "green" }} />{" "}
                          </Typography>
                        </div>
                      ) : (
                        <Typography variant="p" style={{ color: "red" }}>
                          {msg}
                        </Typography>
                      )}
                    </Box>

                    <Div sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                      <JumboTextField
                        fullWidth
                        name="amount"
                        label="Amount"
                        type="number"
                      />
                    </Div>

                    <Div sx={{ mt: 1, pl: 2, pr: 2 }}>
                      <LoadingButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        size="large"
                        sx={{ mb: 3 }}
                        loading={isSubmitting}
                        disabled={buttonStatus}
                      >
                        Submit
                      </LoadingButton>
                    </Div>
                  </Form>
                )}
              </Formik>
            </List>
          )}
        </JumboCardQuick>
      </Grid>
    </Grid>
  );
};

export default CommissionDistribution;
