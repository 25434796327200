import React, { useEffect } from 'react';
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { Autocomplete, Box,  CircularProgress, Grid, List, TextField } from "@mui/material";
import { useState } from 'react';
import { postRequest, updatesettingdata } from 'backendServices/ApiCalls';
import * as yup from "yup";
import { Form, Formik } from "formik";
import Div from '@jumbo/shared/Div/Div';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import { LoadingButton } from '@mui/lab';


const validationSchema = yup.object({
  country: yup
    .object()
    .required('country is required'),
  state: yup
    .object()
    .required('state is required'),
    city: yup
    .object()
    .required("city is required"),
    amount: yup
    .number()
    .required("Shipping amount  required"),
    
  
});

const AddShipping = ({fetchShippingList,setalertData}) => {
    const [countrieslList, setCountriesList] = useState([]);
    const [stateslList, setStatesList] = useState([]);
    const [citieslList, setCitiesList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);


  const fetchCountriesList = () => {

    postRequest('getcountries','', (response) => {
      setCountriesList(response?.data?.data);
      setIsLoading(false);
    });
  };


  useEffect(() => {
    fetchCountriesList();
  }, []);

  const handleSubmit = (data, setSubmitting,resetForm) => {

    const newData = {
        countryid : data?.country?.id,
        stateid : data?.state?.id,
        cityid : data?.city?.id,
        amount : data?.amount
    };
    postRequest('addnewshippigcharges',newData, (response) => {

      if (response?.data?.status === "error") {
        setalertData({
          show: true,
          message: response?.data?.message,
          variant: "error"
        })
        setSubmitting(false)

      } else if (response?.data?.status === "success") {
        setalertData({
          show: true,
          message: response?.data?.message,
          variant: "success"
        })
        setSubmitting(false);
        resetForm()
        fetchShippingList()
      }
    }, (error) => {
      console.log(error?.response?.data);
    })
  };

  if(isLoading)
  {
      return  <Div
      sx={{
          display: 'flex',
          minWidth: 0,
          alignItems: 'center',
          alignContent: 'center',
          height: '100%',
      }}
    >
      <CircularProgress sx={{m: '-40px auto 0'}}/>
    </Div>
  }

  return (
    <Grid container fullWidth sm={12} xs={12}  alignItems="center" justifyContent="center">
      <Grid item sm={12} xs={12}>
        <JumboCardQuick title={"Add Shipping Charges"} noWrapper >

          {isLoading ? (
            <Div
              sx={{
                mt: "20%",
                ml: "45%",
                mb: "20%"
              }}
            >
              <CircularProgress />
            </Div>
          ) : (
            <List disablePadding sx={{ mb: 2 }}>
              <Formik
                initialValues={{
                  country: null,
                  state: null,
                  city: null,
                  amount: null,

                }}
                validationSchema={validationSchema}
                onSubmit={(data, { setSubmitting, resetForm }) => {
                  setSubmitting(true);
                  handleSubmit(data, setSubmitting,resetForm);
                }}
              >

                {({ isSubmitting, values, setFieldValue,touched,errors }) => (
                  <Form style={{ textAlign: 'left' }} noValidate autoComplete='off'>
                    <Grid container spacing={2} p={2}>
                        <Grid item sm={6}>
                        <Autocomplete
                      sx={{ width: "100%" }}
                      value={values.country}
                      onChange={(event, newValue) => {
                        setFieldValue('country',newValue);
                        postRequest('getstates',{ countryid: newValue?.id }, (response) => {
                        if(response?.data?.status === "success")
                          {
                            setStatesList(response?.data?.data);
                          }
                        });
                      }}
                      id="vehmake-select"
                      options={countrieslList}
                      autoHighlight
                      getOptionLabel={(option) => option.name} // Display the title in the dropdown
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      } // Match by code
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="country"
                          label="Select Country"
                          variant="outlined"
                          error={touched.country && errors.country}
                          helperText={touched.country && errors.country}
                        />
                      )}
                    />
                        </Grid>
                        <Grid item sm={6}>
                        <Autocomplete
                      sx={{ width: "100%" }}
                      value={values.state}
                      onChange={(event, newValue) => {
                        setFieldValue('state',newValue);
                        postRequest('getcities',{ stateid: newValue?.id }, (response) => {
                          if(response?.data?.status === "success")
                          {
                            setCitiesList(response?.data?.data);
                          }else{
                            setCitiesList([{id:newValue?.id, name:newValue?.name }]);
                          }
                        });
                      }}
                      id="vehmake-select"
                      options={stateslList}
                      autoHighlight
                      getOptionLabel={(option) => option.name} // Display the title in the dropdown
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      } // Match by code
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="state"
                          label="Select state"
                          variant="outlined"
                          error={touched.state && errors.state}
                          helperText={touched.state && errors.state}
                        />
                      )}
                    />
                        </Grid>
                        <Grid item sm={6}>
                        <Autocomplete
                      sx={{ width: "100%" }}
                      value={values.city}
                      onChange={(event, newValue) => {
                        setFieldValue('city',newValue);
                      }}
                      id="vehmake-select"
                      options={citieslList}
                      autoHighlight
                      getOptionLabel={(option) => option.name} // Display the title in the dropdown
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      } // Match by code
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="city"
                          label="Select city"
                          variant="outlined"
                          error={touched.city && errors.city}
                          helperText={touched.city && errors.city}
                        />
                      )}
                    />
                        </Grid>
                        <Grid item sm={6}>
                        <JumboTextField
                            fullWidth
                            name="amount"
                            label="Shipping Amount ($)"
                            type="number"
                          />
                        </Grid>
                    </Grid>
                  

                    <Div sx={{ mt: 1, pl: 2, pr: 2 }}>
                      <LoadingButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        size="large"
                        sx={{ mb: 3 }}
                        loading={isSubmitting}
                      >
                        Submit
                      </LoadingButton>
                    </Div>
                  </Form>
                )}
              </Formik>
            </List>
          )}
        </JumboCardQuick>
      </Grid>
    </Grid>
  );

};

export default AddShipping;
